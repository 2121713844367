import React from 'react';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Title">
          <h1>Aksel Kornesj&ouml;</h1>
          <p className="Subtitle">I do stuff sometimes</p>
        </div>

        <p className="Links">
          <a href="https://github.com/jefvel" rel="noopener noreferrer" target="_blank">
            GitHub
          </a>
          <a href="https://twitter.com/jefvel" rel="noopener noreferrer" target="_blank">
            Twitter
          </a>
        </p>
      </header>
    </div>
  );
}

export default App;
